<template>
    <ul class="mt-auto sub_menu">
        <h2 class="d-md-none">{{ $t('header.tools') }}</h2>

		<!-- Notifs -->
		<li v-if="userAccess.hasDevAccess" :key="menus_notif.title">
			<a
				href="#"
				@click.prevent
				active-class="menuActive"
				v-b-toggle="`collapse-${menus_notif.title}`"
			>
				<i
					:disabled="hide_tooltip"
					v-b-tooltip.right
					:title="$t(menus_notif.title)"
				>
					<font-awesome-icon :icon="menus_notif.icon" />
				</i>
				{{ $t(menus_notif.title) }}

				<i class="fal fa-angle-down">
					<font-awesome-icon :icon="['fal', 'angle-down']"/>
				</i>
			</a>

			<b-collapse
				v-if="menus_notif.children"
				accordion="accordionMenu"
				tag="ul"
				class="submenu"
				:id="`collapse-${menus_notif.title}`"
			>
				<li v-for="child in menus_notif.children" :key="child.title">
					<a href="#" @click.prevent="child.action">
						<i v-b-tooltip.right :title="$t(child.title)">
							<font-awesome-icon :icon="child.icon" />
						</i> {{ $t(child.title) }}
					</a>
				</li>
			</b-collapse>
		</li>

        <li v-if="userAccess.hasDevAccess">
            <a href="" v-on:click.prevent="reload">
                <i v-b-tooltip.right title="Relog config">
                    <font-awesome-icon :icon="['fal', 'database']" />
                </i> Reload config
            </a>
        </li>
        <li>
            <InstallPwa/>
        </li>
        <li>
		    <a
		        href="#"
		        @click.prevent="updateServiceWorker"
		    >
		        <i>
		            <font-awesome-icon :icon="['fal', 'sync-alt']" />
		        </i>
		        {{ $t('global.update_groomy') }}
		    </a>
        </li>
        <li>
            <a href="" v-on:click.prevent="logout">
                <i v-b-tooltip.right :title="$t('header.logout')">
					<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                    <font-awesome-icon v-else :icon="['fal', 'door-open']" />
                </i> {{ $t('header.logout') }}
            </a>
        </li>
        <li>
			<div id="synchro">
				<Synchronizer/>
				<Indexer/>
			</div>
        </li>
    </ul>
</template>

<script>
import Constants from '../../../config.local.js'
import User from '@/mixins/User.js'

export default {
    mixins: [User],
    components: {
		InstallPwa: () => import('@/components/Offline/InstallPwa'),
		Synchronizer: () => import('@/components/Offline/Synchronizer'),
		Indexer: () => import('@/components/Offline/Indexer'),
	},
	data() {
		return {
			processing: false,
			hide_tooltip: false,
			menus_notif: {
				title: 'Notifications',
				icon: ['fal', 'bell'],
				children: [
					{
						title: 'Actes planifiés',
						icon: ['fal', 'bell'],
						action: this.sendActsTodoTestNotification
					}, {
						title: 'Actes en retard',
						icon: ['fal', 'bell'],
						action: this.sendActsDelayedTestNotification
					}, {
						title: 'Actes nécessitant une ordonnace',
						icon: ['fal', 'bell'],
						action: this.sendActsDocumentTestNotification
					}, {
						title: 'Repro quotidienne',
						icon: ['fal', 'bell'],
						action: this.sendActsReproDaily
					}, {
						title: 'Repro hebdo',
						icon: ['fal', 'bell'],
						action: this.sendActsReproWeekly
					}, {
						title: 'Repro stats',
						icon: ['fal', 'bell'],
						action: this.sendActsReproStats
					}, {
						title: 'Repro rappel poulinage',
						icon: ['fal', 'bell'],
						action: this.sendActsReproTerm
					}, {
						title: 'Rappel registre',
						icon: ['fal', 'bell'],
						action: this.sendRegistryReminderTestNotification
					}
				]
			}
		}
	},
    computed: {
        userAccess() {
            return this.$store.state.userAccess
		}
	},
    methods: {
        async logout() {
			this.processing = true
            await this.delogUser()
			this.$store.commit('nav/closeFooter')
			this.processing = false
        },
        async reload() {
            let licence_key = this.getConfig("licence_key")
            let token = this.getConfig("token")
            await this.rmConfig();
            await this.setConfig("licence_key", licence_key)
            await this.setConfig("token", token)
            await this.loadConfig()
            location.reload()
        },
        sendSampleNotification() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/sample/${Constants.USER_LICENCE_KEY}/${this.getConfig('user_id')}`
            this.$request.request_post_api('Notification::sendSample', url)
		},
		sendActsTodoTestNotification() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/test_act_todo/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsTodoTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsDelayedTestNotification() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/test_act_delay/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDelayedTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsDocumentTestNotification() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/test_act_document/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsReproDaily() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/test_act_repro_daily/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsReproWeekly() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/test_act_repro_weekly/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsReproStats() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/test_act_repro_stats/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendActsReproTerm() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/test_act_repro_term/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendActsDocumentTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		sendRegistryReminderTestNotification() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/test_registry_reminder/?licence_key=${Constants.USER_LICENCE_KEY}`
            this.$request.request_get_api('Notification::sendRegistryReminderTestNotification', url).then(this.successToast('toast.notif_test_ok'))
		},
		updateServiceWorker() {
			window.location.reload()
		}
    }
}
</script>